import React, { Component } from "react";
import Feathers from "../../redux/FeathersRedux";
import { Grid, Header, Modal, Loader } from "semantic-ui-react";
import CreateTaskModal from "../../components/TaskCalendar/CreateTaskModal";
import InfoTaskModal from "../../components/TaskCalendar/InfoTaskModal";
import moment from "moment";

//FullCalendar
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";

//Import stylesheets
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/list/main.css";

export default class CalendarContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarWeekends: true,
      calendar_task: [],
      calendar_task_info: {
        id: null,
        clave: null,
        titulo: "",
        tipo_tarea: null,
        maquina_mantenimiento: null,
        tiene_periodicidad: false,
        periodicidad: null,
        descripcion: "",
        fecha_creacion: null,
        allDay: false,
        fecha_inicio: new Date(),
        fecha_fin: new Date(),
        perfil_usuario: null,
        usuario: null,
        status_tarea: null,
        status_tarea_id: null,
        status_color: "",
        entregable: false,
        descripcion_entregable: "",
        tarea_file: [],
        codigo_iso_id: null,
        tipo_mantenimiento_id: null,
      },
      createTaskModal: false,
      infoTaskModal: false,
      loading: false,
      dateSelected: new Date(),

      perfiles: [],
      maquinas: [],
      periodos: [],
      tipos_tarea: [],
      tipos_mantenimiento: [],
      codigos: [],

      startDate: '',
      endDate: '',
    };
  }

  openCreateModal = (info) => {
    this.setState({
      createTaskModal: true,
      dateSelected: info.dateStr,
    });
  };

  openInfoModal2 = async  (info) => {
    let { id, title, start, end, backgroundColor } = info.event;
    await Feathers.service("calendario_tarea").find({
      query: {
        id: id,
        $limit: "-1",
      }
    })
    .then((res) => {
     
      this.setState({
        infoTaskModal: true,
        calendar_task_info: {
          id: res[0].id,
          titulo: title,
          descripcion: res[0].descripcion,
          fecha_creacion: res[0].fecha_creacion,
          fecha_inicio: res[0].fecha_inicio,
          hora_inicio: res[0].hora_inicio,
          fecha_fin: res[0].fecha_fin,
          hora_fin: res[0].hora_fin,
          perfil_usuario: res[0].perfil_usuario_id,
          usuario: res[0].usuario_id,
          tipo_tarea: res[0].tipo_tarea_id,
          maquina_mantenimiento: res[0].maquina_mantenimiento_id,
          status_tarea: res[0].status_tarea_calendario.status_tarea,
          status_color: backgroundColor,
          status_tarea_id: res[0].status_tarea_id,
          entregable: res[0].entregable,
          descripcion_entregable: res[0].descripcion_entregable,
          tarea_file: res[0].tarea_file,
          periodicidad: res[0].periodicidad_id,
          tiene_periodicidad: res[0].tiene_periodicidad,
          allDay: res[0].allDay,
          clave: res[0].clave,
          codigo_iso_id: res[0].codigo_iso_id,
          tipo_mantenimiento_id: res[0].tipo_mantenimiento_id
        },
      });
    })
  }

  openInfoModal = (info) => {
    let { id, title, start, end, backgroundColor } = info.event;
    let {
      descripcion,
      fecha_creacion,
      usuario,
      tipo_tarea,
      status_tarea,
      status_tarea_id,
      entregable,
      descripcion_entregable,
      tarea_file,
      perfil_usuario_id,
      periodicidad_id,
      tiene_periodicidad,
      allDay,
      clave,
      fecha_inicio,
      hora_inicio,
      fecha_fin,
      hora_fin,
      maquina_mantenimiento_id,
      codigo_iso_id,
      tipo_mantenimiento_id
    } = info.event.extendedProps;
    this.setState({
      infoTaskModal: true,
      calendar_task_info: {
        id: id,
        titulo: title,
        descripcion: descripcion,
        fecha_creacion: fecha_creacion,
        fecha_inicio: fecha_inicio,
        hora_inicio: hora_inicio,
        fecha_fin: fecha_fin,
        hora_fin: hora_fin,
        perfil_usuario: perfil_usuario_id,
        usuario: usuario,
        tipo_tarea: tipo_tarea,
        maquina_mantenimiento: maquina_mantenimiento_id,
        status_tarea: status_tarea,
        status_color: backgroundColor,
        status_tarea_id: status_tarea_id,
        entregable: entregable,
        descripcion_entregable: descripcion_entregable,
        tarea_file: tarea_file,
        periodicidad: periodicidad_id,
        tiene_periodicidad: tiene_periodicidad,
        allDay: allDay,
        clave: clave,
        codigo_iso_id: codigo_iso_id,
        tipo_mantenimiento_id: tipo_mantenimiento_id
      },
    });
  };


  //Fetch Tasks
  fetchTask = async () => {
    const startFormat = moment(this.state.startDate).format('YYYY-MM-DD');
    const endFormat = moment(this.state.endDate).add(1, 'M').format('YYYY-MM-DD');

    this.setState({
      loading: true,
    });
    await Feathers.service("calendario_tarea")
      .find({
        query: {
          $limit: "-1",
          fecha_inicio: {
            $gte: startFormat
          },
          fecha_fin: {
            $lte: endFormat
          },
        },
      })
      .then((calendar_task) => {
        //console.log(calendar_task, 'las tareas')
        this.setState({
          calendar_task: calendar_task.map((t) => {
            return {
              id: t.id,
              title: t.titulo,
              start: `${t.fecha_inicio} ${t.hora_inicio}`,
              end: `${t.fecha_fin} ${t.hora_fin}`,
              backgroundColor: t.status_tarea_calendario.color,
              borderColor: t.status_tarea_calendario.color,
              /* extendedProps: {
                descripcion: t.descripcion,
                fecha_creacion: t.fecha_creacion,
                perfil_usuario_id: t.perfil_usuario_id,
                usuario: t.usuario_id,
                tipo_tarea: t.tipo_tarea.id,
                maquina_mantenimiento_id: t.maquina_mantenimiento_id,
                status_tarea_id: t.status_tarea_id,
                status_tarea: t.status_tarea_calendario.status_tarea,
                entregable: t.entregable,
                descripcion_entregable: t.descripcion_entregable,
                tarea_file: t.tarea_file,
                periodicidad_id: t.periodicidad_id,
                tiene_periodicidad: t.tiene_periodicidad,
                allDay: t.allDay,
                clave: t.clave,
                fecha_inicio: t.fecha_inicio,
                hora_inicio: t.hora_inicio,
                fecha_fin: t.fecha_fin,
                hora_fin: t.hora_fin,
                codigo_iso_id: t.codigo_iso_id,
                tipo_mantenimiento_id: t.tipo_mantenimiento_id
              }, */
            };
          }),
          loading: false,
        });
      });
  };

  closeModal = () => {
    this.setState({
      createTaskModal: false,
      infoTaskModal: false,
    });
    //this.fetchTask();
  };


  //CONSULTAS PARA SELECTS DE MODAL CREATE -------

  fetchPeriodicidad = async () => {
    await Feathers.service("periodicidad")
      .find({
        query: {},
      })
      .then((res) => {
        this.setState({
          periodos: res.data.map((p) => {
            return {
              value: p.id,
              text: p.periodo,
            };
          }),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchMachine = async () => {
    await Feathers.service("maquinaria")
      .find({
        query: {
          $select: ["id", "nombre", 'tipo_maquina_id']
        },
      })
      .then((res) => {
        this.setState({
          maquinas: res.data.map((m) => {
            return {
              value: m.id,
              text: m.nombre,
            };
          }),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchCodigos = async () => {
    await Feathers.service('codigos_iso').find({
      query: {}
    })
    .then((res) => {
      this.setState({
        codigos: res.data.map((c) => {
          return {
            value: c.id,
            text: c.code
          }
        })
      })
    })
    .catch((err) => {
      console.warn(err);
    })
  }

  fetchProfiles = async () =>{
    await Feathers.service("perfil")
      .find({
        query: {},
      })
      .then((res) => {
        this.setState({
          perfiles: res.data.map((p) => {
            return {
              value: p.id,
              text: p.nombre,
            };
          }),
        });
      })
      .catch((err) => {});
  }

  fetchTiposTarea = async () => {
    await Feathers.service("tipo_tarea")
      .find({
        query: {
          $limit: "-1",
        },
      })
      .then((tipos_tarea) => {
        this.setState({
          tipos_tarea: tipos_tarea.map((t) => {
            return {
              value: t.id,
              text: t.nombre,
            };
          })
        })  
      })
    
  }

  fetchTiposMantenimiento = async () => {
    await Feathers.service("tipos_mantenimiento")
      .find({
        query: {
          $limit: "-1",
        },
      })
      .then((res) => {
        this.setState({
          tipos_mantenimiento: res.map((t) => {
            return {
              value: t.id,
              text: t.nombre,
            };
          })
        })  
      })
    
  }

  datesRender = async (info) => {
    await this.setState({
      startDate: info.view.activeStart,
      endDate: info.view.activeEnd,
    })
    this.fetchTask();
  }

  //------------------------------------------------

  componentDidMount() {
    //this.fetchTask();

    //----------------------------
    this.fetchPeriodicidad();
    this.fetchMachine();
    this.fetchProfiles();
    this.fetchTiposTarea();
    this.fetchCodigos();
    this.fetchTiposMantenimiento();
  }

  render() {
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header
                as="h2"
                icon="calendar alternate outline"
                content="Calendario de Tareas"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {this.state.loading ? <Loader active inline="centered" /> : <></>}
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <div className="app-calendar">
                <FullCalendar
                  defaultView="dayGridMonth"
                  showNonCurrentDates={false}
                  header={{
                    left: "prev, next today",
                    center: "title",
                    right: "dayGridMonth, listWeek",
                  }}
                  plugins={[
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin,
                    listPlugin,
                  ]}
                  weekends={this.state.calendarWeekends}
                  events={this.state.calendar_task}
                  dateClick={this.openCreateModal}
                  locale="es"
                  eventClick={this.openInfoModal2}
                  eventLimit="true"
                  displayEventEnd="false"
                  navLinks="true"
                  lazyFetching="true"
                  datesRender={this.datesRender}
                  timeZone="America/Mexico_City"
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <CreateTaskModal
          open={this.state.createTaskModal}
          onClose={this.closeModal}
          dateSelected={this.state.dateSelected}
          callback={this.fetchTask}

          perfiles= {this.state.perfiles}
          maquinas= {this.state.maquinas}
          periodos= {this.state.periodos}
          tipos_tarea= {this.state.tipos_tarea}
          tipos_mantenimiento= {this.state.tipos_mantenimiento}
          codigos= {this.state.codigos}
        />

        <InfoTaskModal
          data={this.state.calendar_task_info}
          open={this.state.infoTaskModal}
          onClose={this.closeModal}
          callback={this.fetchTask}

          perfiles= {this.state.perfiles}
          maquinas= {this.state.maquinas}
          periodos= {this.state.periodos}
          tipos_tarea= {this.state.tipos_tarea}
          tipos_mantenimiento= {this.state.tipos_mantenimiento}
          codigos= {this.state.codigos}
        />
      </div>
    );
  }
}
