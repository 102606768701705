import React, { Component } from "react";
import {
  Header,
  Segment,
  Grid,
  Icon,
  Form,
  Button,
  Table,
  Modal,
  Label,
  Divider,
  Checkbox,
} from "semantic-ui-react";
import InfiniteScroll from "react-infinite-scroll-component";
import Feathers, { services } from "../../../redux/FeathersRedux";
import { TaskStatus } from "../../../components/Tasks";
import Timer from "../../../components/Timer";
import swal from "sweetalert2";
import moment from "moment";
import VulcanizationDetail from "../../../components/Tasks/Vulcanization/VulcanizationDetail";
import VulcanizationTemperature from "../../../components/Tasks/Vulcanization/VulcanizationTemperature";
import { connect } from "react-redux";

const MIN_TIME = 6; //25200

class VulcanizationTaskInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finishingTask: false,
      selectAll: false,
      pending: [],
      selected: [],
      total: 0,
      page: 0,
      loading: true,
      modalIsOpened: false,
      selectedCount: 0,
      detail: [],
      temperatures: [],
      configuraciones: {
        espera_vulcanizado: null,
      },
      comment: "",
      updatingCommentLoading: false,
      temperatura: "",
      task: null,
      rodillo: null,
      partida: null,
      pedido: null,
    };

    this.nextData = this.nextData.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.renderTaskStatus = this.renderTaskStatus.bind(this);
    this.initVulcanization = this.initVulcanization.bind(this);
    this.renderItemsToChoose = this.renderItemsToChoose.bind(this);
    this.getDetail = this.getDetail.bind(this);
    this.finishTask = this.finishTask.bind(this);
    this.fetchTask = this.fetchTask.bind(this);
  }

  async componentDidMount() {
    console.log("---", this.props);
    if (this.props.match.params.id) {
      //get detalle vulcanizado
      await this.getTemperatures();
      await this.fetchTask();
      await this.getDetail();
      await this.nextData();
    }

    this.fetchComentario();

    this.fetchConfiguraciones();
  }

  fetchComentario = async (_) => {
    this.setState({
      comment: this.state.task
        ? this.state.task.comentario
        : "Ningun comentario",
    });
  };
  fetchTask = async (_) => {
    await Feathers.service("vulcanizado")
      .find({
        query: {
          id: this.props.match.params.id,
        },
      })
      .then((res) => {
        this.setState({ task: res.data[0] });
      });
  };

  fetchConfiguraciones = async (_) => {
    let espera_vulcanizado = await this.props.getEspera_Vulcanizado();

    espera_vulcanizado = +espera_vulcanizado.value[0];

    this.setState({
      configuraciones: {
        ...this.state.configuraciones,
        espera_vulcanizado,
      },
    });
  };

  async getDetail() {
    await Feathers.service("detalle_vulcanizado")
      .find({
        query: {
          vulcanizado_id: this.props.match.params.id,
        },
      })
      .then((detail) => {
        let rodillo = detail.data[0].rodillo || detail[0].rodillo;
        let partida = rodillo.partida;
        let pedido = partida.pedido;
        console.log("rodillo", rodillo, partida, pedido);
        this.setState({
          detail: detail[0] || detail.data[0],
          loading: false,
          rodillo,
          partida,
          pedido,
        });
      });
  }

  getTemperatures = async () => {
    Feathers.service("detalles_temperatura")
      .find({
        query: {
          tarea_vulcanizado_id: this.props.match.params.id,
          $limit: "-1",
        },
      })
      .then((res) => {
        console.log("--------", res, "temps", this.state.task);
        this.setState({
          temperatures: res.map((e) => {
            return {
              hora: moment(e.hora_captura).format("HH:mm"),
              temperatura: e.temperatura,
              //"presion": e.presion
            };
          }),
        });
      });
  };

  nextData() {
    /**
     *
     * el limit 15 hace que sólo me traiga de 15 en 15 elementos, y como hay mas de 15 y el page no funciona bien
     * entonces me está trayendo nomás 15
     *
     */
    Feathers.service("pendiente_vulcanizado")
      .find({
        query: {
          $limit: "-1",
          // $skip: 15 * this.state.page,
          $sort: {
            rodillo_id: this.state.rodillo.id,
          },
        },
      })
      .then((res) => {
        // return
        let respuesta = JSON.parse(JSON.stringify(res));
        console.log(res, "resp", respuesta, this.state.rodillo.id);
        // si es horno puliuretano, que es la vulcanizadora 3, sólo se podrán elegir poliuretanos para vulcanizar
        if (this.state.task.vulcanizadora_id === 3) {
          respuesta = respuesta.filter((v) => {
            if (
              v.rodillo.partida.producto_id === 3 ||
              v.rodillo.partida.producto_id === 4
            )
              return v;
          });
        } else {
          respuesta = respuesta.filter((v) => {
            if (
              v.rodillo.partida.producto_id !== 3 &&
              v.rodillo.partida.producto_id !== 4
            )
              return v;
          });
        }
        this.setState(
          {
            pending: res,
            total: res.length,
            loading: false,
            page: this.state.page + 1,
          },
          () => {
            console.log("Resss", this.state);
          }
        );
      });
  }

  toggleSelect = (row) => {
    this.setState({
      selectAll: false,
    });
    let pending = [...this.state.pending];
    let count = -1;
    let index = pending.findIndex((p) => p.id === row.id);

    pending[index].selected = !pending[index].selected;

    if (pending[index].selected) count = 1;

    let selected = pending.filter((p) => p.selected);
    let selectedCount = this.state.selectedCount + count;

    this.setState({
      pending,
      selectedCount,
      selected,
      modalIsOpened:
        this.state.modalIsOpened && selectedCount === 0
          ? false
          : this.state.modalIsOpened,
    });
  };

  toggleModal() {
    this.setState({
      modalIsOpened: !this.state.modalIsOpened,
    });
  }

  initVulcanization() {
    swal({
      title: "¿Iniciar proceso de vulcanizado?",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (result) => {
      if (!result.value) return;
      //await this.getTemperature();
      Feathers.service("vulcanizado")
        .patch(this.props.task.id, {
          hora_inicio_vulcanizado: moment().format("YYYY-MM-DD HH:mm:ss"),
          getTemperature: true,
          vulcanizadoraId: this.props.task.vulcanizadora_id,
        })
        .then((res) => {
          Feathers.service("detalle_vulcanizado")
            .create(
              this.state.selected.map((s) => {
                Feathers.service("detalle_vulcanizado").patch(s.rodillo_id, {
                  stage: 4,
                });
                return {
                  vulcanizado_id: this.props.task.id,
                  rodillo_id: s.rodillo_id,
                };
              })
            )
            .then((res) => {
              this.toggleModal();
              this.state.updateTask && this.state.updateTask();
              this.getDetail();
              this.getTemperatures();
            });
        });
    });
  }

  finishTask() {
    if (!this.state.task.hora_inicio_vulcanizado)
      return swal({
        title: "Atención",
        text:
          "Primero debes haber iniciado el proceso de vulcanizado para finalizar la tarea",
        type: "info",
      });

    //7 horas //25200
    if (
      moment().diff(
        moment(this.state.task.hora_inicio_vulcanizado),
        "seconds"
      ) < this.state.configuraciones.espera_vulcanizado
    )
      return swal({
        title: "Atención",
        text: `Debe haber transucurrido al menos ${this.state.configuraciones
          .espera_vulcanizado /
          3600} hora/s del proceso de vulcanización para finalizar la tarea`,
        type: "info",
      });

    this.setState(
      {
        finishingTask: true,
      },
      (_) => {
        this.props.finishTask && this.props.finishTask();
      }
    );
  }

  renderStatus() {
    if (this.state.task.hora_inicio_vulcanizado)
      return (
        <Label color="teal">
          <Icon name="thermometer empty" />
          Vulcanizando
        </Label>
      );

    return (
      <Label color="blue">
        <Icon name="play" /> Iniciada
      </Label>
    );
  }

  renderTaskStatus() {
    return (
      <div style={{ margin: "calc(2rem - .14285714em) 0 1rem" }}>
        <Header
          as="h3"
          attached="top"
          inverted
          style={{
            paddingTop: "5px",
            paddingBottom: "5px",
            backgroundColor: "#393e46",
            borderRadius: "10px",
          }}
        >
          <Icon name="settings" />
          <Header.Content>Estatus de la tarea</Header.Content>
        </Header>
        <Segment size="large">
          <Grid>
            <Grid.Row>
              <Grid.Column computer="4">
                <span style={{ display: "block", fontWeight: "bold" }}>
                  Estatus
                </span>
                {this.renderStatus()}
              </Grid.Column>
              <Grid.Column computer="4">
                <span style={{ display: "block", fontWeight: "bold" }}>
                  Hora inicio
                </span>
                <span>{this.state.task.hora_inicio}</span>
              </Grid.Column>
              <Grid.Column computer="4">
                <span style={{ display: "block", fontWeight: "bold" }}>
                  Hora inicio vulcanizado
                </span>
                <span>{this.state.task.hora_inicio_vulcanizado}</span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    );
  }

  handleSelectAll = (checked) => {
    this.setState({
      selectAll: checked,
    });

    let pending = [...this.state.pending];
    let count = 0;

    pending = pending.map((v) => {
      v.selected = checked;
      return v;
    });

    count = checked ? pending.length : 0;

    let selected = pending.filter((p) => p.selected === true);
    let selectedCount = count;

    this.setState({
      pending,
      selectedCount,
      selected,
      modalIsOpened:
        this.state.modalIsOpened && selectedCount === 0
          ? false
          : this.state.modalIsOpened,
    });
  };

  renderItemsToChoose() {
    // if (this.state.task.hora_inicio_vulcanizado) return null;
    return (
      <div>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <InfiniteScroll
                next={this.nextData}
                hasMore={
                  this.state
                    .loading /* aqui estaba esto this.state.total !== this.state.pending.length pero como ya no se mostrará todo siempre, no funcionará*/
                }
                height={"10vh"}
                loader={<h1>Loading...</h1>}
              >
                <Table selectable striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Unidad</Table.HeaderCell>
                      {/* <Table.HeaderCell>SAE</Table.HeaderCell> */}
                      <Table.HeaderCell>Cliente</Table.HeaderCell>
                      <Table.HeaderCell>Tipo</Table.HeaderCell>
                      <Table.HeaderCell>Parte - Mancheta</Table.HeaderCell>
                      <Table.HeaderCell>Material</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row
                      key={this.state.rodillo.id}
                      //onClick={() => this.toggleSelect(p, index)}
                    >
                      <Table.Cell>{this.state.rodillo.id || "N/A"}</Table.Cell>
                      <Table.Cell>
                        {this.state.pedido.cliente.nombre || "N/A"}
                      </Table.Cell>
                      <Table.Cell>
                        {this.state.partida.producto.nombre || "N/A"}
                      </Table.Cell>
                      <Table.Cell>
                      {this.state.partida.mancheta ? this.state.partida.mancheta.nombre : "N/A"}
                      </Table.Cell>
                      <Table.Cell>
                      {this.state.partida.material.nombre || "N/A"}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </InfiniteScroll>
            </Grid.Column>
          </Grid.Row>
          {this.state.selectedCount > 0 && (
            <Grid.Row>
              <Grid.Column>
                <Button
                  floated="right"
                  color="green"
                  onClick={this.toggleModal}
                >
                  <div style={{ textAlign: "center" }}>
                    <span>
                      {this.state.selectedCount} rodillos seleccionado(s)
                    </span>
                    <p>Ver rodillos</p>
                  </div>
                </Button>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </div>
    );
  }

  _addComment = async () => {
    await this.setState({
      updatingCommentLoading: true,
    });

    let comentario = await Feathers.service("vulcanizado").patch(
      this.props.task.id,
      {
        comentario: this.state.comment,
      }
    );

    await this.setState({
      updatingCommentLoading: false,
    });
  };

  renderCommentsBox = (_) => {
    return (
      <div style={{ margin: "calc(2rem - .14285714em) 0 1rem" }}>
        <Header
          as="h3"
          attached="top"
          inverted
          style={{
            paddingTop: "5px",
            paddingBottom: "5px",
            backgroundColor: "#393e46",
            borderRadius: "10px",
          }}
        >
          <Icon name="comments" />
          <Header.Content>Área de Comentario</Header.Content>
        </Header>
        <Segment size="large">
          <Form>
            <Form.Field>
              <Form.TextArea
                label={"Comentario"}
                placeholder="Comentario..."
                value={this.state.comment}
                onChange={(_, d) => {
                  this.setState({
                    comment: d.value,
                  });
                }}
              />
            </Form.Field>
            <Form.Field>
              <Form.Button
                icon={"comment outline"}
                loading={this.state.updatingCommentLoading}
                color={"green"}
                content="Actualizar"
                onClick={this._addComment}
              />
            </Form.Field>
          </Form>
        </Segment>
      </div>
    );
  };

  render() {
    return !this.state.loading ? (
      <Segment size={"large"} loading={this.state.finishingTask}>
        {this.state.pedido && (
          <>
            <Header as="h2">
              Vulcanizadora:{" "}
              {this.state.task.vulcanizadora
                ? this.state.task.vulcanizadora.nombre
                : ""}{" "}
            </Header>
            <div style={{ margin: "calc(2rem - .14285714em) 0 1rem" }}>
              <Header
                as="h3"
                attached="top"
                inverted
                style={{
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  backgroundColor: "#393e46",
                  borderRadius: "10px",
                }}
              >
                <Icon name="info" />
                <Header.Content>Información del pedido</Header.Content>
              </Header>
              <Segment size={"large"}>
                <Grid>
                  <Grid.Row
                    columns={5}
                    style={{
                      paddingTop: "0.5rem",
                      paddingBottom: "0.5rem",
                    }}
                  >
                    <Grid.Column>
                      <span style={{ display: "block", fontWeight: "bold" }}>
                        #Pedido
                      </span>
                      <span>{this.state.pedido.id}</span>
                    </Grid.Column>
                    <Grid.Column>
                      <span style={{ display: "block", fontWeight: "bold" }}>
                        Cliente
                      </span>
                      <span>{this.state.pedido.cliente.nombre}</span>
                    </Grid.Column>
                    <Grid.Column>
                      <span style={{ display: "block", fontWeight: "bold" }}>
                        Fecha alta
                      </span>
                      <span>{this.state.pedido.fecha_alta}</span>
                    </Grid.Column>
                    <Grid.Column>
                      <span style={{ display: "block", fontWeight: "bold" }}>
                        Fecha entrega
                      </span>
                      <span>{this.state.pedido.fecha_entrega}</span>
                    </Grid.Column>
                    <Grid.Column>
                      <span style={{ display: "block", fontWeight: "bold" }}>
                        Kilos totales
                      </span>
                      <span>{this.state.partida.kilos}</span>
                    </Grid.Column>
                    <Grid.Column>
                      <span style={{ display: "block", fontWeight: "bold" }}>
                        Orden de compra
                      </span>
                      <span>
                        <span>{this.state.pedido.orden_compra || "N/A"}</span>
                      </span>
                    </Grid.Column>
                    <Grid.Column>
                      <span style={{ display: "block", fontWeight: "bold" }}>
                        Número de salida
                      </span>
                      <span>
                        <span>{this.state.pedido.numero_salida || "N/A"}</span>
                      </span>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </div>
            {this.renderTaskStatus()}
            {this.renderItemsToChoose()}
            <VulcanizationDetail detail={this.state.detail} />
            <VulcanizationTemperature
              temperatures={this.state.temperatures}
              getTemperature={this.getTemperatures}
            />
            <Modal open={this.state.modalIsOpened} size={"large"}>
              <Modal.Header style={{ backgroundColor: "#f3f3f3" }}>
                Rodillos seleccionados
                <Button
                  circular
                  size="mini"
                  basic
                  icon="close"
                  floated="right"
                  onClick={this.toggleModal}
                ></Button>
              </Modal.Header>
              <Modal.Content scrolling>
                <Table striped compact>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Unidad</Table.HeaderCell>
                      <Table.HeaderCell>SAE</Table.HeaderCell>
                      <Table.HeaderCell>Cliente</Table.HeaderCell>
                      <Table.HeaderCell>Tipo</Table.HeaderCell>
                      <Table.HeaderCell>Parte - Mancheta</Table.HeaderCell>
                      <Table.HeaderCell>Material</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.selected.map((p, index) => {
                      return (
                        <Table.Row key={index}>
                          <Table.Cell>{p.rodillo_id}</Table.Cell>
                          <Table.Cell>
                            {p.rodillo.partida.pedido.ref_sae}
                          </Table.Cell>
                          <Table.Cell>
                            {p.rodillo.partida.pedido.cliente.nombre}
                          </Table.Cell>
                          <Table.Cell>
                            {p.rodillo.partida.producto.nombre}
                          </Table.Cell>
                          <Table.Cell>
                            {p.rodillo.partida.mancheta
                              ? p.rodillo.partida.mancheta.parte
                              : "---"}
                          </Table.Cell>
                          <Table.Cell>
                            {p.rodillo.partida.material.nombre}
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </Modal.Content>
            </Modal>
          </>
        )}
      </Segment>
    ) : (
      ""
    );
  }
}

const mapStateToProps = (state) => {
  return {
    configuraciones: state.services.configuraciones,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEspera_Vulcanizado: () =>
      dispatch(
        services.configuraciones.find({
          query: {
            nombre: "espera_vulcanizado",
            $limit: "-1",
          },
        })
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VulcanizationTaskInfo);
