import React from "react";
import {
  Button,
  Popup,
  Header,
  Segment,
  Table,
  Loader,
  Icon,
  Dropdown,
} from "semantic-ui-react";
import Feathers from "../redux/FeathersRedux";
import TaskStatus from "../components/TaskCalendar/TaskStatus";
import ButtonsAction from "../components/TaskCalendar/ButtonsAction";
import { connect } from "react-redux";
import moment from "moment";
import ViewTask from "./Tasks/HomeCheckTasks/ViewTask";
import { push } from "react-router-redux";

class InicioTarea extends React.Component {
  constructor() {
    super();
    this.state = {
      tasks: [],
      taskAllUser: [],
      tasksByPermission: {},
      tasksMolino: [],
      tasksDesbaste: [],
      tasksDesviste: [],
      tasksRectificado: [],
      tasksDesmonte: [],
      tasksRevision: [],
      tasksVulcanizado: [],
      tasksCorte: [],
      taskConstruccionRodillo: [],
      taskConstruccionMancheta: [],
      taskConstruccionPoli: [],
      calidadRodillos: [],
      calidadManchetas: [],
      calidadOtros: [],
      task: {},
      profiles: [],
      permissionsId: [],
      taskperMonth: [],
      userId: 0,
      loading: false,
      loadingTasks: true,
      isExpired: false,
      filterNumberTask: [
        { value: 5, text: "Mostrar mis primeras 5 tareas" },
        { value: 10, text: "Mostrar mis primeras 10 tareas" },
        { value: 20, text: "Mostrar mis primeras 20 tareas" },
        { value: 30, text: "Mostrar mis primeras 30 tareas" },
      ],
    };
  }

  fetchTask = (value) => {
    this.setState({
      loading: true,
    });
    let userId = this.props.auth.user.id;
    let profiles = this.props.auth.user.profiles;

    const threeMonthsAgo = moment().subtract(3, 'months').format("YYYY-MM-DD")
    const lastMonth = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD")
    const lastDayNexMonth = moment().add(1, 'months').endOf('month').format("YYYY-MM-DD")

    let firstDayMonth = moment().startOf("month");
    let formatFirstDayMonth = moment(firstDayMonth).format("YYYY-MM-DD");
    const lastDayMonth = moment().endOf("month");
    let formatLastDayMonth = moment(lastDayMonth).format("YYYY-MM-DD");

    Feathers.service("calendario_tarea")
      .find({
        query: {
          //El limite por defecto de tareas a mostrar es 10, el usuario puede cambiarlo...
          //...para solo ver 5, 10, 20 o 30 tareas a la vez.
          $limit: value || 5, // ask initial table row size to show
          activo: 1,
          usuario_id: userId,
          $sort: {
            fecha_inicio: 1,
          },
          status_tarea_id: {
            $in: [1, 2],
          },
          fecha_inicio: {
            $gte: threeMonthsAgo,
          },
          fecha_fin: {
            $lte: lastDayNexMonth,
          },
        },
      })
      .then((res) => {
        const previousTask = res.data.filter((task) => {
          return (task.fecha_inicio >= threeMonthsAgo &&
            task.fecha_fin <= formatLastDayMonth && task.status_tarea_id === 2
          );
        });

        const currentTask = res.data.filter((task) => {
          return (task.fecha_inicio >= formatFirstDayMonth &&
            task.fecha_fin <= lastDayNexMonth && moment(task.fecha_inicio).month() === moment().month()
          );
        });

        const allTask = [...new Set([...currentTask ,...previousTask])]

        this.setState({
          tasks: allTask,
          loading: false,
        });

        if (
          this.props.auth.user.profiles.includes(1) ||
          this.props.auth.user.profiles.includes(2)
        ) {
          Feathers.service("calendario_tarea")
            .find({
              query: {
                activo: 1,
                fecha_inicio: {
                  $gte: threeMonthsAgo,
                },
                fecha_fin: {
                  $lte: lastDayNexMonth,
                },
                usuario_id: {
                  $ne: userId,
                },
              },
            })
            .then((res) => {
              this.setState({
                taskAllUser: res.data,
                profiles: profiles,
                loading: false,
              });
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getTasksByPermission = async (permissions) => {
    // if (permissions.includes(15)) {
    //   let resRevision = await Feathers.service("revision").find();
    //   this.setState({
    //     tasksRevision: resRevision.data,
    //   });
    // }
    if (permissions.includes(16)) {
      let resMolino = await Feathers.service("molino").find({
        query: {
          $limit: 1,
        },
      });
      this.setState({
        tasksMolino: resMolino.data,
      });
    }
    if (permissions.includes(17)) {
      let resDesviste = await Feathers.service("desviste").find();
      this.setState({
        tasksDesviste: resDesviste.data,
      });
    }
    if (permissions.includes(18)) {
      let resConstruccionRodillo = await Feathers.service(
        "construccion_rodillo"
      ).find();
      this.setState({
        taskConstruccionRodillo: resConstruccionRodillo.data,
      });
    }
    if (permissions.includes(19)) {
      let resConstruccionManchetas = await Feathers.service(
        "construccion_manchetas"
      ).find();
      this.setState({
        taskConstruccionMancheta: resConstruccionManchetas.data,
      });
    }
    if (permissions.includes(20)) {
      let resConstruccionPoli = await Feathers.service(
        "construccion_poliuretano"
      ).find();
      this.setState({
        taskConstruccionPoli: resConstruccionPoli.data,
      });
    }
    if (permissions.includes(21)) {
      let resVulcanizado = await Feathers.service("detalle_vulcanizado").find();
      let resVulca = await Feathers.service("vulcanizado").find();

      let filteredVulca = resVulca.data.filter((data) => {
        return (
          // data.status_tarea_id !== 1
          data.status_tarea_id === null && data.hora_inicio === null
        );
      });

      let filteredByUser = resVulca.data.filter((data) => {
        return (
          // data.status_tarea_id !== 1
          data.status_tarea_id === 1 &&
          data.hora_fin === null &&
          data.usuario_id === this.props.auth.user.id
        );
      });

      let vulcaIds = filteredVulca.map((item) => item.id);

      let sortedData = resVulcanizado.sort((a, b) => {
        let nameA = a.rodillo.partida.pedido.fecha_entrega; // ignore upper and lowercase
        let nameB = b.rodillo.partida.pedido.fecha_entrega; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      let filteredVulcanizado = sortedData.reverse().filter((data) => {
        return vulcaIds.includes(data.vulcanizado_id);
      });

      this.setState({
        tasksVulcanizado: filteredByUser.length
          ? [filteredByUser[0]]
          : filteredVulcanizado.length
            ? [filteredVulcanizado[0]]
            : [],
      });
    }
    if (permissions.includes(22)) {
      let resDesmonte = await Feathers.service("desmonte").find();
      this.setState({
        tasksDesmonte: resDesmonte.data,
      });
    }
    if (permissions.includes(23)) {
      let resDesbaste = await Feathers.service("desbaste").find();
      this.setState({
        tasksDesbaste: resDesbaste.data,
      });
    }
    if (permissions.includes(24)) {
      let resRectificado = await Feathers.service("rectificado").find();
      this.setState({
        tasksRectificado: resRectificado.data,
      });
    }
    if (permissions.includes(25)) {
      let resCorte = await Feathers.service("corte").find();
      this.setState({
        tasksCorte: resCorte.data,
      });
    }
    if (permissions.includes(26)) {
      let calidad_rodillos = await Feathers.service(
        "control_calidad_rodillos"
      ).find();
      console.log("rodillos", calidad_rodillos);
      this.setState({
        calidadRodillos: calidad_rodillos.data,
      });
    }
    // DO NOT LOOK FOR QA TASKS
    // if (permissions.includes(27)) {
    //   let calidad_manchetas = await Feathers.service("control_calidad").find();
    //   this.setState({
    //     calidadManchetas: calidad_manchetas.data,
    //   });
    // }
    if (permissions.includes(28)) {
      let calidad_otros = await Feathers.service(
        "control_calidad_otros"
      ).find();

      this.setState({
        calidadOtros: calidad_otros.data,
      });
    }
    this.setState({ loadingTasks: false });
  };

  //validar si una fecha ya está vencida
  validateFinalDate = (fecha_fin) => {
    const currentDate = moment().format("YYYY-MM-DD");
    const validate = moment(fecha_fin).isBefore(currentDate);
    if (validate) {
      return (
        <Popup
          trigger={<Icon circular color="red" name="alarm" />}
          content="Esta tarea ya está fuera del periodo establecido"
          size="large"
        />
      );
    }
  };

  async componentDidMount() {
    let permissionArray = [];

    this.fetchTask();

    if (
      !(
        this.props.auth.user.profiles.includes(1) ||
        this.props.auth.user.profiles.includes(2) ||
        this.props.auth.user.profiles.includes(25)
      )
    ) {
      this.props.auth.user.currentPermissions.map((permission) => {
        permissionArray.push(permission.permiso_id);
      });
      this.getTasksByPermission(permissionArray);
    }

    console.log(permissionArray, "permisso");
    this.setState({
      permissionsId: permissionArray,
    });
  }

  renderActions(row, task) {
    const directToTask = (row, task) => {
      switch (task) {
        case "molino":
          this.props.dispatch(push(`/task/molino/${row.tareaId}`));
          break;
        case "desbaste":
          this.props.dispatch(push(`/task/desbaste/${row.tareaId}`));
          break;
        // case "revision":
        //   this.props.dispatch(push(`/task/revision/${row.tareaId}`));
        //   break;
        case "desviste":
          this.props.dispatch(push(`/task/desviste/${row.tareaId}`));
          break;
        case "desmonte":
          this.props.dispatch(push(`/task/desmonte/${row.tareaId}`));
          break;
        case "vulcanizado":
          this.props.dispatch(push(`/task/vulcanizado/${row.vulcanizado_id}`));
          break;
        case "corte":
          this.props.dispatch(push(`/task/corte/${row.tareaId}`));
          break;
        case "rectificado":
          this.props.dispatch(push(`/task/rectificado/${row.tareaId}`));
          break;
        case "construccion_rodillo":
          this.props.dispatch(
            push(`/task/construccion_rodillo/${row.tareaId}`)
          );
          break;
        case "construccion_mancheta":
          this.props.dispatch(
            push(`/task/construccion_machetas/${row.tareaId}`)
          );
          break;
        case "construccion_poli":
          this.props.dispatch(
            push(`/task/construccion_poliuretano/${row.tareaId}`)
          );
          break;
        case "calidadOtros":
          this.props.dispatch(push(`/task/calidad_otros/${row.tareaId}`));
          break;
        case "calidadRodillos":
          this.props.dispatch(push(`/task/calidad_rodillo/${row.tareaId}`));
          break;
        case "calidadManchetas":
          this.props.dispatch(push(`/task/calidad_manchetas/${row.tareaId}`));
          break;
      }
    };
    return (
      <>
        <Button
          onClick={() => {
            directToTask(row, task);
          }}
          icon="play"
          size="mini"
          basic
          color="blue"
          content="Ver"
        />
      </>
    );
  }

  render() {
    return (
      <Segment>
        <Header as="h2" icon="pin" content="Tareas Administrativas" />

        <Dropdown
          label="Filtrar por número de tareas "
          placeholder="Seleccione un número de tareas a mostrar"
          selection
          options={this.state.filterNumberTask}
          onChange={(e, { name, value }) => this.fetchTask(value)}
          icon="filter"
          floating
          labeled
          className="icon"
          button
        />

        {this.state.loading ? (
          <Loader active inline="centered" />
        ) : (
          <>
            <Table celled padded textAlign="center">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Tarea</Table.HeaderCell>
                  <Table.HeaderCell>Tipo</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Acciones</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.tasks.map((item) => (
                  <Table.Row key={item.id}>
                    <Table.Cell
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div style={{ marginRight: "0.5rem" }}>
                        {this.validateFinalDate(item.fecha_fin)}
                      </div>
                      <div>{item.titulo}</div>
                    </Table.Cell>
                    <Table.Cell>{item.tipo_tarea.nombre}</Table.Cell>
                    <Table.Cell>
                      <TaskStatus status={item.status_tarea_calendario.id} />
                    </Table.Cell>
                    <Table.Cell>
                      <ButtonsAction
                        status={item.status_tarea_calendario.id}
                        isEntregable={item.entregable}
                        callback={this.fetchTask}
                        data={item}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </>
        )}

        {this.props.auth.user.profiles.includes(1) ||
          this.props.auth.user.profiles.includes(2) ||
          this.props.auth.user.profiles.includes(25) ? (
          <div>
            <Header as="h2" icon="pin" content="Tareas de todos los usuarios" />
            {this.state.taskAllUser ? (
              <Table celled padded textAlign="center" size="large">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Nombre</Table.HeaderCell>
                    <Table.HeaderCell>Tarea</Table.HeaderCell>
                    <Table.HeaderCell>Descripión</Table.HeaderCell>
                    <Table.HeaderCell>Tipo</Table.HeaderCell>
                    <Table.HeaderCell>Acciones</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.taskAllUser.map((item) => (
                    <Table.Row key={item.id}>
                      <Table.Cell>
                        {item.usuario.nombre} {item.usuario.apellido}
                      </Table.Cell>
                      <Table.Cell
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div style={{ marginRight: "0.5rem" }}>
                          {this.validateFinalDate(item.fecha_fin)}
                        </div>
                        <div>{item.titulo}</div>
                      </Table.Cell>
                      <Table.Cell>{item.descripcion}</Table.Cell>
                      <Table.Cell>{item.tipo_tarea.nombre}</Table.Cell>
                      <Table.Cell>{item.fecha_inicio}</Table.Cell>
                      <Table.Cell>{item.fecha_fin}</Table.Cell>
                      <Table.Cell>
                        <TaskStatus status={item.status_tarea_calendario.id} />
                      </Table.Cell>
                      {/* <Table.Cell>
                        <ButtonsAction
                          status={item.status_tarea_calendario.id}
                          isEntregable={item.entregable}
                          callback={this.fetchTask}
                          data={item}
                        />
                      </Table.Cell> */}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}

        {this.props.auth.user.profiles.includes(1) !== true &&
          this.props.auth.user.profiles.includes(2) !== true &&
          this.props.auth.user.profiles.includes(10) !== true &&
          this.props.auth.user.profiles.includes(25) !== true &&
          this.state.loadingTasks ? (
          <>
            <Header as="h2" icon="pin" content="Tareas Operativas" />
            <Loader active inline="centered" />
            <center>
              <p>Obteniendo tareas operativas...</p>
            </center>
          </>
        ) : (
          <>
            {this.props.auth.user.profiles.includes(1) !== true &&
              this.props.auth.user.profiles.includes(2) !== true &&
              this.props.auth.user.profiles.includes(10) !== true &&
              this.props.auth.user.profiles.includes(25) !== true && (
                <div>
                  <Header as="h2" icon="pin" content="Tareas Operativas" />
                  {this.state.profiles ? (
                    <div>
                      {/* {this.state.permissionsId.includes(15) && (
                        <>
                          <Header
                            as="h5"
                            icon="calendar"
                            content="Tareas de Revisión"
                          />
                          <Table celled padded textAlign="center" size="small">
                            <Table.Header>
                              <Table.HeaderCell>Rodillo</Table.HeaderCell>
                              <Table.HeaderCell>Cliente</Table.HeaderCell>
                              <Table.HeaderCell>Fecha Ent.</Table.HeaderCell>
                              <Table.HeaderCell>Material</Table.HeaderCell>
                              <Table.HeaderCell>Dureza</Table.HeaderCell>
                              <Table.HeaderCell>DN</Table.HeaderCell>
                              <Table.HeaderCell>DE</Table.HeaderCell>
                              <Table.HeaderCell>LC</Table.HeaderCell>
                              <Table.HeaderCell>LT</Table.HeaderCell>
                              <Table.HeaderCell>Kilos</Table.HeaderCell>
                              <Table.HeaderCell>Cantidad</Table.HeaderCell>
                              <Table.HeaderCell>Acciones</Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                              {this.state.tasksRevision &&
                                this.state.tasksRevision.map((mapTask) => (
                                  <Table.Row key={mapTask.id}>
                                    <Table.Cell>
                                      {mapTask.rodillo_id
                                        ? mapTask.rodillo_id
                                        : "---"}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {mapTask.clienteNombre}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {mapTask.fecha_entrega}
                                    </Table.Cell>
                                    <Table.Cell>{mapTask.matNombre}</Table.Cell>
                                    <Table.Cell>{mapTask.valor}</Table.Cell>
                                    <Table.Cell>{mapTask.dn}</Table.Cell>
                                    <Table.Cell>{mapTask.de}</Table.Cell>
                                    <Table.Cell>{mapTask.lc}</Table.Cell>
                                    <Table.Cell>{mapTask.lt}</Table.Cell>
                                    <Table.Cell>
                                      {mapTask.partidaKilos}
                                    </Table.Cell>
                                    <Table.Cell>{mapTask.cantidad}</Table.Cell>
                                    <Table.Cell>
                                      {this.renderActions(mapTask, "revision")}
                                    </Table.Cell>
                                  </Table.Row>
                                ))}
                            </Table.Body>
                          </Table>
                        </>
                      )} */}

                      {this.state.permissionsId.includes(16) && (
                        <>
                          <Header
                            as="h5"
                            icon="calendar"
                            content="Tareas de Molino"
                          />
                          <Table celled padded textAlign="center" size="small">
                            <Table.Header>
                              <Table.HeaderCell>Cliente</Table.HeaderCell>
                              <Table.HeaderCell>Fecha Ent.</Table.HeaderCell>
                              <Table.HeaderCell>Material</Table.HeaderCell>
                              <Table.HeaderCell>Dureza</Table.HeaderCell>
                              <Table.HeaderCell>DN</Table.HeaderCell>
                              <Table.HeaderCell>DE</Table.HeaderCell>
                              <Table.HeaderCell>LC</Table.HeaderCell>
                              <Table.HeaderCell>LT</Table.HeaderCell>
                              <Table.HeaderCell>Kilos</Table.HeaderCell>
                              <Table.HeaderCell>Cantidad</Table.HeaderCell>
                              <Table.HeaderCell>Acciones</Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                              {this.state.tasksMolino &&
                                this.state.tasksMolino.map((mapTask) => (
                                  <Table.Row key={mapTask.id}>
                                    <Table.Cell>
                                      {mapTask.clienteNombre}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {mapTask.fecha_entrega}
                                    </Table.Cell>
                                    <Table.Cell>{mapTask.matNombre}</Table.Cell>
                                    <Table.Cell>{mapTask.valor}</Table.Cell>
                                    <Table.Cell>{mapTask.dn}</Table.Cell>
                                    <Table.Cell>{mapTask.de}</Table.Cell>
                                    <Table.Cell>{mapTask.lc}</Table.Cell>
                                    <Table.Cell>{mapTask.lt}</Table.Cell>
                                    <Table.Cell>{mapTask.kilos}</Table.Cell>
                                    <Table.Cell>{mapTask.cantidad}</Table.Cell>
                                    <Table.Cell>
                                      {this.renderActions(mapTask, "molino")}
                                    </Table.Cell>
                                  </Table.Row>
                                ))}
                            </Table.Body>
                          </Table>
                        </>
                      )}

                      {this.state.permissionsId.includes(17) && (
                        <>
                          <Header
                            as="h5"
                            icon="calendar"
                            content="Tareas de Desviste"
                          />
                          <Table celled padded textAlign="center" size="small">
                            <Table.Header>
                              <Table.HeaderCell>Cliente</Table.HeaderCell>
                              <Table.HeaderCell>#Unidad</Table.HeaderCell>
                              <Table.HeaderCell>Fecha Ent.</Table.HeaderCell>
                              <Table.HeaderCell>Material</Table.HeaderCell>
                              <Table.HeaderCell>Dureza</Table.HeaderCell>
                              <Table.HeaderCell>DN</Table.HeaderCell>
                              <Table.HeaderCell>DE</Table.HeaderCell>
                              <Table.HeaderCell>LC</Table.HeaderCell>
                              <Table.HeaderCell>LT</Table.HeaderCell>
                              <Table.HeaderCell>Kilos</Table.HeaderCell>
                              <Table.HeaderCell>Cantidad</Table.HeaderCell>
                              <Table.HeaderCell>Acciones</Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                              {this.state.tasksDesviste &&
                                this.state.tasksDesviste.map((mapTask) => (
                                  <Table.Row key={mapTask.id}>
                                    <Table.Cell>
                                      {mapTask.clienteNombre}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {mapTask.rodillo_id}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {mapTask.fecha_entrega}
                                    </Table.Cell>
                                    <Table.Cell>{mapTask.matNombre}</Table.Cell>
                                    <Table.Cell>{mapTask.valor}</Table.Cell>
                                    <Table.Cell>{mapTask.dn}</Table.Cell>
                                    <Table.Cell>{mapTask.de}</Table.Cell>
                                    <Table.Cell>{mapTask.lc}</Table.Cell>
                                    <Table.Cell>{mapTask.lt}</Table.Cell>
                                    <Table.Cell>{mapTask.kilos}</Table.Cell>
                                    <Table.Cell>{mapTask.cantidad}</Table.Cell>
                                    <Table.Cell>
                                      {this.renderActions(mapTask, "desviste")}
                                    </Table.Cell>
                                  </Table.Row>
                                ))}
                            </Table.Body>
                          </Table>
                        </>
                      )}

                      {/* Construcción */}

                      {this.state.permissionsId.includes(18) && (
                        <>
                          <Header
                            as="h5"
                            icon="calendar"
                            content="Tareas de Construcción - Rodillos"
                          />
                          <Table celled padded textAlign="center" size="small">
                            <Table.Header>
                              <Table.HeaderCell>Cliente</Table.HeaderCell>
                              <Table.HeaderCell>#Unidad</Table.HeaderCell>
                              <Table.HeaderCell>Fecha Entr.</Table.HeaderCell>
                              <Table.HeaderCell>Material</Table.HeaderCell>
                              <Table.HeaderCell>DN</Table.HeaderCell>
                              <Table.HeaderCell>DE</Table.HeaderCell>
                              <Table.HeaderCell>LC</Table.HeaderCell>
                              <Table.HeaderCell>LT</Table.HeaderCell>
                              <Table.HeaderCell>Kilos</Table.HeaderCell>
                              <Table.HeaderCell>Cantidad</Table.HeaderCell>
                              <Table.HeaderCell>Acciones</Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                              {this.state.taskConstruccionRodillo &&
                                this.state.taskConstruccionRodillo.map(
                                  (mapTask) => (
                                    <Table.Row key={mapTask.id}>
                                      <Table.Cell>
                                        {mapTask.clienteNombre}
                                      </Table.Cell>
                                      <Table.Cell>
                                        {mapTask.rodillo_id}
                                      </Table.Cell>
                                      <Table.Cell>
                                        {mapTask.fecha_entrega}
                                      </Table.Cell>
                                      <Table.Cell>
                                        {mapTask.matNombre}
                                      </Table.Cell>
                                      {/* <Table.Cell>{mapTask.dn}</Table.Cell>
                                      <Table.Cell>{mapTask.de}</Table.Cell>
                                      <Table.Cell>{mapTask.lc}</Table.Cell>
                                      <Table.Cell>{mapTask.lt}</Table.Cell> */}
                                      <Table.Cell>{mapTask.kilos}</Table.Cell>
                                      <Table.Cell>
                                        {mapTask.cantidad}
                                      </Table.Cell>
                                      <Table.Cell>
                                        {this.renderActions(
                                          mapTask,
                                          "construccion_rodillo"
                                        )}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                            </Table.Body>
                          </Table>
                        </>
                      )}
                      {this.state.permissionsId.includes(19) && (
                        <>
                          <Header
                            as="h5"
                            icon="calendar"
                            content="Tareas de Construcción - Manchetas"
                          />
                          <Table celled padded textAlign="center" size="small">
                            <Table.Header>
                              <Table.HeaderCell>Parte</Table.HeaderCell>
                              <Table.HeaderCell>Forma</Table.HeaderCell>
                              <Table.HeaderCell>
                                Pendientes/totales
                              </Table.HeaderCell>
                              <Table.HeaderCell>Altura</Table.HeaderCell>
                              <Table.HeaderCell>Dureza</Table.HeaderCell>
                              <Table.HeaderCell>Espesor</Table.HeaderCell>
                              <Table.HeaderCell>Perímetro</Table.HeaderCell>
                              <Table.HeaderCell>Acciones</Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                              {this.state.taskConstruccionMancheta &&
                                this.state.taskConstruccionMancheta.map(
                                  (mapTask) => (
                                    <Table.Row key={mapTask.id}>
                                      <Table.Cell>
                                        {this.renderActions(
                                          mapTask,
                                          "construccion_mancheta"
                                        )}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                            </Table.Body>
                          </Table>
                        </>
                      )}

                      {this.state.permissionsId.includes(20) && (
                        <>
                          <Header
                            as="h5"
                            icon="calendar"
                            content="Tareas de Construcción - Poliuretanos"
                          />
                          <Table celled padded textAlign="center" size="small">
                            <Table.Header>
                              <Table.HeaderCell>Cliente</Table.HeaderCell>
                              <Table.HeaderCell>Producto</Table.HeaderCell>
                              <Table.HeaderCell>Material</Table.HeaderCell>
                              <Table.HeaderCell>Cantidad</Table.HeaderCell>
                              <Table.HeaderCell>Acciones</Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                              {this.state.taskConstruccionPoli &&
                                this.state.taskConstruccionPoli.map(
                                  (mapTask) => (
                                    <Table.Row key={mapTask.id}>
                                      <Table.Cell>
                                        {mapTask.clienteNombre}
                                      </Table.Cell>
                                      <Table.Cell>
                                        {mapTask.prodNombre}
                                      </Table.Cell>

                                      <Table.Cell>
                                        {mapTask.matNombre}
                                      </Table.Cell>
                                      <Table.Cell>
                                        {mapTask.cantidad}
                                      </Table.Cell>
                                      <Table.Cell>
                                        {this.renderActions(
                                          mapTask,
                                          "construccion_poli"
                                        )}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )}
                            </Table.Body>
                          </Table>
                        </>
                      )}

                      {this.state.permissionsId.includes(21) && (
                        <>
                          <Header
                            as="h5"
                            icon="calendar"
                            content="Tareas de Vulcanizado"
                          />
                          <Table celled padded textAlign="center" size="small">
                            <Table.Header>
                              <Table.HeaderCell>Cliente</Table.HeaderCell>
                              <Table.HeaderCell>Unidad</Table.HeaderCell>
                              <Table.HeaderCell>Tipo</Table.HeaderCell>
                              <Table.HeaderCell>Material</Table.HeaderCell>
                              <Table.HeaderCell>Acciones</Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                              {this.state.tasksVulcanizado &&
                                this.state.tasksVulcanizado.map((mapTask) => (
                                  <Table.Row key={mapTask.id}>
                                    <Table.Cell>
                                      {
                                        mapTask.rodillo.partida.pedido.cliente
                                          .nombre
                                      }
                                    </Table.Cell>
                                    <Table.Cell>
                                      {mapTask.rodillo_id}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {mapTask.rodillo.partida.producto.nombre}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {mapTask.rodillo.partida.material.nombre}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {this.renderActions(
                                        mapTask,
                                        "vulcanizado"
                                      )}
                                    </Table.Cell>
                                  </Table.Row>
                                ))}
                            </Table.Body>
                          </Table>
                        </>
                      )}

                      {this.state.permissionsId.includes(22) && (
                        <>
                          <Header
                            as="h5"
                            icon="calendar"
                            content="Tareas de Desmonte"
                          />
                          <Table celled padded textAlign="center" size="small">
                            <Table.Header>
                              <Table.HeaderCell>Cliente</Table.HeaderCell>
                              <Table.HeaderCell>
                                Tipo de Producto
                              </Table.HeaderCell>
                              <Table.HeaderCell>#Unidad</Table.HeaderCell>
                              <Table.HeaderCell>Cantidad</Table.HeaderCell>
                              <Table.HeaderCell>Acciones</Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                              {this.state.tasksDesmonte &&
                                this.state.tasksDesmonte.map((mapTask) => (
                                  <Table.Row key={mapTask.id}>
                                    <Table.Cell>
                                      {mapTask.clienteNombre}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {mapTask.prodNombre}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {mapTask.rodillo_id}
                                    </Table.Cell>
                                    <Table.Cell>{mapTask.cantidad}</Table.Cell>
                                    <Table.Cell>
                                      {this.renderActions(mapTask, "desmonte")}
                                    </Table.Cell>
                                  </Table.Row>
                                ))}
                            </Table.Body>
                          </Table>
                        </>
                      )}

                      {this.state.permissionsId.includes(23) && (
                        <>
                          <Header
                            as="h5"
                            icon="calendar"
                            content="Tareas de Desbaste"
                          />
                          <Table celled padded textAlign="center" size="small">
                            <Table.Header>
                              <Table.HeaderCell>Cliente</Table.HeaderCell>
                              <Table.HeaderCell>#Unidad</Table.HeaderCell>
                              <Table.HeaderCell>Tipo</Table.HeaderCell>
                              <Table.HeaderCell>DN</Table.HeaderCell>
                              <Table.HeaderCell>DE</Table.HeaderCell>
                              <Table.HeaderCell>LC</Table.HeaderCell>
                              <Table.HeaderCell>LT</Table.HeaderCell>
                              <Table.HeaderCell>Material</Table.HeaderCell>
                              <Table.HeaderCell>Dureza</Table.HeaderCell>
                              <Table.HeaderCell>Cantidad</Table.HeaderCell>
                              <Table.HeaderCell>Acciones</Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                              {this.state.tasksDesbaste &&
                                this.state.tasksDesbaste.map((mapTask) => (
                                  <Table.Row key={mapTask.id}>
                                    <Table.Cell>
                                      {mapTask.clienteNombre}
                                    </Table.Cell>
                                    <Table.Cell>{mapTask.unidad}</Table.Cell>
                                    <Table.Cell>
                                      {mapTask.prodNombre}
                                    </Table.Cell>
                                    <Table.Cell>{mapTask.dn}</Table.Cell>
                                    <Table.Cell>{mapTask.de}</Table.Cell>
                                    <Table.Cell>{mapTask.lc}</Table.Cell>
                                    <Table.Cell>{mapTask.lt}</Table.Cell>
                                    <Table.Cell>{mapTask.matNombre}</Table.Cell>
                                    <Table.Cell>{mapTask.dureza}</Table.Cell>
                                    <Table.Cell>{mapTask.cantidad}</Table.Cell>
                                    <Table.Cell>
                                      {this.renderActions(mapTask, "desbaste")}
                                    </Table.Cell>
                                  </Table.Row>
                                ))}
                            </Table.Body>
                          </Table>
                        </>
                      )}

                      {this.state.permissionsId.includes(24) && (
                        <>
                          <Header
                            as="h5"
                            icon="calendar"
                            content="Tareas de Rectificado"
                          />
                          <Table celled padded textAlign="center" size="small">
                            <Table.Header>
                              <Table.HeaderCell>Cliente</Table.HeaderCell>
                              <Table.HeaderCell>#Unidad</Table.HeaderCell>
                              <Table.HeaderCell>Tipo</Table.HeaderCell>
                              <Table.HeaderCell>DN</Table.HeaderCell>
                              <Table.HeaderCell>DE</Table.HeaderCell>
                              <Table.HeaderCell>LC</Table.HeaderCell>
                              <Table.HeaderCell>LT</Table.HeaderCell>
                              <Table.HeaderCell>Material</Table.HeaderCell>
                              <Table.HeaderCell>Dureza</Table.HeaderCell>
                              <Table.HeaderCell>Cantidad</Table.HeaderCell>
                              <Table.HeaderCell>Acciones</Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                              {this.state.tasksRectificado &&
                                this.state.tasksRectificado.map((mapTask) => (
                                  <Table.Row key={mapTask.id}>
                                    <Table.Cell>
                                      {mapTask.clienteNombre}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {mapTask.rodillo_id}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {mapTask.prodNombre}
                                    </Table.Cell>
                                    <Table.Cell>{mapTask.dn}</Table.Cell>
                                    <Table.Cell>{mapTask.de}</Table.Cell>
                                    <Table.Cell>{mapTask.lc}</Table.Cell>
                                    <Table.Cell>{mapTask.lt}</Table.Cell>
                                    <Table.Cell>{mapTask.matNombre}</Table.Cell>
                                    <Table.Cell>{mapTask.valor}</Table.Cell>
                                    <Table.Cell>{mapTask.cantidad}</Table.Cell>
                                    <Table.Cell>
                                      {this.renderActions(
                                        mapTask,
                                        "rectificado"
                                      )}
                                    </Table.Cell>
                                  </Table.Row>
                                ))}
                            </Table.Body>
                          </Table>
                        </>
                      )}

                      {this.state.permissionsId.includes(25) && (
                        <>
                          <Header
                            as="h5"
                            icon="calendar"
                            content="Tareas de Corte"
                          />
                          <Table celled padded textAlign="center" size="small">
                            <Table.Header>
                              <Table.HeaderCell>Cliente</Table.HeaderCell>
                              <Table.HeaderCell>#Unidad</Table.HeaderCell>
                              <Table.HeaderCell>Tipo</Table.HeaderCell>
                              <Table.HeaderCell>Cantidad</Table.HeaderCell>
                              <Table.HeaderCell>Acciones</Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                              {this.state.tasksCorte &&
                                this.state.tasksCorte.map((mapTask) => (
                                  <Table.Row key={mapTask.id}>
                                    <Table.Cell>
                                      {mapTask.clienteNombre}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {mapTask.rodillo_id}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {mapTask.prodNombre}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {mapTask.prodNombre}
                                    </Table.Cell>
                                    <Table.Cell>{mapTask.cantidad}</Table.Cell>
                                    <Table.Cell>
                                      {this.renderActions(mapTask, "corte")}
                                    </Table.Cell>
                                  </Table.Row>
                                ))}
                            </Table.Body>
                          </Table>
                        </>
                      )}

                      {/* CONTROL DE CALIDAD */}

                      {/* QA REMOVED */}
                      {/* 
                      {this.state.permissionsId.includes(26) && (
                        <>
                          <Header
                            as="h5"
                            icon="calendar"
                            content="Tareas de Control de calidad - Otros"
                          />
                          <Table celled padded textAlign="center" size="small">
                            <Table.Header>
                              <Table.HeaderCell>Cliente</Table.HeaderCell>
                              <Table.HeaderCell>#Unidad</Table.HeaderCell>
                              <Table.HeaderCell>Tipo</Table.HeaderCell>
                              <Table.HeaderCell>Material</Table.HeaderCell>
                              <Table.HeaderCell>Dureza</Table.HeaderCell>
                              <Table.HeaderCell>Cantidad</Table.HeaderCell>
                              <Table.HeaderCell>Acciones</Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                              {this.state.calidadOtros &&
                                this.state.calidadOtros.map((mapTask) => (
                                  <Table.Row key={mapTask.id}>
                                    <Table.Cell>
                                      {mapTask.clienteNombre}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {mapTask.rodillo_id}
                                    </Table.Cell>
                                    {/* <Table.Cell>{mapTask.prodNombre !== null && mapTask.prodNombre !== "" ? mapTask.prodNombre : "---"}</Table.Cell> */}
                      {/* <Table.Cell>{"---"}</Table.Cell>
                                    <Table.Cell>{mapTask.matNombre}</Table.Cell>
                                    <Table.Cell>{mapTask.valor}</Table.Cell>
                                    <Table.Cell>{mapTask.cantidad}</Table.Cell>
                                    <Table.Cell>
                                      {this.renderActions(
                                        mapTask,
                                        "calidadOtros"
                                      )}
                                    </Table.Cell>
                                  </Table.Row>
                                ))}
                            </Table.Body>
                          </Table>
                        </>
                      )}  */}

                      {this.state.permissionsId.includes(27) && (
                        <>
                          <Header
                            as="h5"
                            icon="calendar"
                            content="Tareas de Control de calidad - Rodillos"
                          />
                          <Table celled padded textAlign="center" size="small">
                            <Table.Header>
                              <Table.HeaderCell>Cliente</Table.HeaderCell>
                              <Table.HeaderCell>#Unidad</Table.HeaderCell>
                              <Table.HeaderCell>Tipo</Table.HeaderCell>
                              <Table.HeaderCell>Material</Table.HeaderCell>
                              {/* <Table.HeaderCell>DN</Table.HeaderCell>
                              <Table.HeaderCell>DE</Table.HeaderCell>
                              <Table.HeaderCell>LC</Table.HeaderCell>
                              <Table.HeaderCell>LT</Table.HeaderCell> */}
                              <Table.HeaderCell>Dureza</Table.HeaderCell>
                              <Table.HeaderCell>Cantidad</Table.HeaderCell>
                              <Table.HeaderCell>Acciones</Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                              {this.state.calidadRodillos &&
                                this.state.calidadRodillos.map((mapTask) => (
                                  <Table.Row key={mapTask.id}>
                                    <Table.Cell>
                                      {mapTask.clienteNombre}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {mapTask.rodillo_id}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {mapTask.prodNombre}
                                    </Table.Cell>
                                    {/* <Table.Cell>{mapTask.dn ? mapTask.dn : "---"}</Table.Cell>
                                    <Table.Cell>{mapTask.de ? mapTask.de : "---"}</Table.Cell>
                                    <Table.Cell>{mapTask.lc ? mapTask.lc : "---"}</Table.Cell>
                                    <Table.Cell>{mapTask.lt ? mapTask.lt : "---"}</Table.Cell> */}
                                    <Table.Cell>{mapTask.matNombre}</Table.Cell>
                                    <Table.Cell>{mapTask.valor}</Table.Cell>
                                    <Table.Cell>{mapTask.cantidad}</Table.Cell>
                                    <Table.Cell>
                                      {this.renderActions(
                                        mapTask,
                                        "calidadRodillos"
                                      )}
                                    </Table.Cell>
                                  </Table.Row>
                                ))}
                            </Table.Body>
                          </Table>
                        </>
                      )}

                      {this.state.permissionsId.includes(28) && (
                        <>
                          <Header
                            as="h5"
                            icon="calendar"
                            content="Tareas de Control de calidad - Manchetas"
                          />
                          <Table celled padded textAlign="center" size="small">
                            <Table.Header>
                              <Table.HeaderCell>Cliente</Table.HeaderCell>
                              <Table.HeaderCell>Tipo</Table.HeaderCell>
                              <Table.HeaderCell>Material</Table.HeaderCell>
                              <Table.HeaderCell>Dureza</Table.HeaderCell>
                              <Table.HeaderCell>Cantidad</Table.HeaderCell>
                              <Table.HeaderCell>Acciones</Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                              {this.state.calidadManchetas &&
                                this.state.calidadManchetas.map((mapTask) => (
                                  <Table.Row key={mapTask.id}>
                                    <Table.Cell>
                                      {mapTask.clienteNombre}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {mapTask.prodNombre !== null &&
                                        mapTask.prodNombre !== ""
                                        ? mapTask.prodNombre
                                        : "---"}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {mapTask.matNombre
                                        ? mapTask.matNombre
                                        : "---"}
                                    </Table.Cell>
                                    <Table.Cell>{mapTask.valor}</Table.Cell>
                                    <Table.Cell>{mapTask.cantidad}</Table.Cell>
                                    <Table.Cell>
                                      {this.renderActions(
                                        mapTask,
                                        "calidadManchetas"
                                      )}
                                    </Table.Cell>
                                  </Table.Row>
                                ))}
                            </Table.Body>
                          </Table>
                        </>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )}
          </>
        )}
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(InicioTarea);
